<!-- 设置密码( = 1) -->
<template>
  <r-e-dialog
      title="添加密码"
      show-footer
      class="layer-contract-detail"
      :visible.sync="dialogVisible"
      width="700px"
      top="10vh"
      @click-submit="clickSubmit"
      @click-cancel="clickCancel"
      @close="clickCancel"
  >
    <el-form
        ref="formPublish"
        label-width="90px"
        size="small"
        :model="formPublish"
        :rules="rules"
        :inline="true"
    >
      <div class="flex">
        <!-- 2023/08/21 张晓瑜 修改天数为单选框 -->
        <el-form-item label="天数：" prop="days" label-width="128px">
          <el-radio-group v-model="formPublish.days" style="width: 450px">
            <el-radio :label="1">1天</el-radio>
            <el-radio :label="3">3天</el-radio>
            <el-radio :label="5">5天</el-radio>
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="手机号:" prop="phone" label-width="120px">
          <el-input
              v-model="formPublish.phone"
              style="width: 450px"
              placeholder="请输入手机号"
          />
        </el-form-item>
      </div>
      <div class="flex">
        <el-form-item label="删除历史密码:" prop="isDelete" label-width="120px">
          <el-radio-group v-model="formPublish.isDelete">
            <el-radio-button :label="false">保留</el-radio-button>
            <el-radio-button :label="true">删除</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </div>
      <!--      <div class="flex">-->
      <!--        <el-form-item label="历史累积时长" label-width="120px">-->
      <!--          <div>{{days}}</div>-->
      <!--        </el-form-item>-->
      <!--      </div>-->

      <el-form-item label="注意：" prop="days" label-width="128px">
        <div style="color: red">册除历史密码将使所有的密码不可用，请谨慎选择</div>
        <!--        <div style="color: red">当历史累计时长超过10天，则需审批通过方可发起</div>-->
      </el-form-item>
    </el-form>
  </r-e-dialog>
</template>

<script>
import {MessageSuccess, MessageError} from '@custom/message'
import {iotTagLockTemporary, GETHistoryTotalDuration, offLinePassApprovalPUT} from '@/api/door-management'
import el from "element-ui/src/locale/lang/el";

export default {
  name: 'dialog-set-lock-temporary',
  props: {
    equipmentData: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      days: null,
      totalHistoryDuration: null,
      timeItr: null,
      dialogVisible: false,
      formPublish: {
        apartmentUuid: null,
        phone: null,
        isDelete: true,
        days: 1 // 默认选中值为1
      },
      rules: {
        days: [
          {required: true, message: '请选择天数', trigger: 'change'}
        ],
        phone: [{required: true, message: '请填写手机号', trigger: 'blur'}],
        isDelete: [
          {
            required: true,
            message: '请选择是否删除历史密码',
            trigger: 'change'
          }
        ]
      },
      cycle: [],
      loadingOptions: {
        lock: true,
        text: 'Loading...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      }
    }
  },
  components: {},
  methods: {

    GETHistoryTotalDurationmet() {
      GETHistoryTotalDuration({apartmentUuid: this.equipmentData.apartmentUuid}).then(({info}) => {
        // const itr = this.isTimestampGreaterThanTenDaysAgo(info)
        console.log(info)
        // alert(itr)
        // 是否超过15天：aboveFifteen      是否超过10天：aboveTen
        this.timeItr = 1
        if (info.aboveTen) this.timeItr = 2
        if (info.aboveFifteen) this.timeItr = 3
        this.totalHistoryDuration = info['历史累计时间戳']
        this.days = info['历史累计时间字符串']

        console.log(info)
      })
    },
    openDialog(lockId) {
      this.GETHistoryTotalDurationmet()
      const oldFormPublish = this.formPublish
      this.formPublish = {...oldFormPublish, apartmentUuid: lockId}
      this.dialogVisible = true
    },
    clickCancel() {
      this.dialogVisible = false
      this.formPublish = {
        phone: null,
        isDelete: true,
        days: null
      }
    },
    clickSubmit() {
      let that = this
      this.$refs['formPublish'].validate(valid => {
        if (valid) {
          // 密码审批代码暂时注释
          // if (this.timeItr === 2) {
          //   // alert(that.timeItr)
          //   this.$alert('<strong>该合同离线密码历史累计时长已超10天<br>需审批通过方可设置成功，是否继续？</strong>', '提示', {
          //     dangerouslyUseHTMLString: true,
          //     distinguishCancelAndClose: true,
          //     showCancelButton: true,
          //     center: true,
          //     confirmButtonText: '确认',
          //     cancelButtonText: '取消'
          //   }).then(() => {
          //     const loadingOptions = this.loadingOptions
          //     const loading = this.$loading({...loadingOptions})
          //     let {apartmentUuid, phone, isDelete, days} = that.formPublish
          //     if (apartmentUuid === null) {
          //       MessageError('当前房源没有智能门锁无法发送')
          //       return
          //     }
          //     apartmentUuid = that.equipmentData.apartmentUuid
          //     offLinePassApprovalPUT({
          //       apartmentUuid,
          //       phone,
          //       isDelete,
          //       days,
          //       totalHistoryDuration: this.totalHistoryDuration,
          //     }).then(res => {
          //       console.log(res)
          //       loading.close()
          //       this.clickCancel()
          //       this.$message({
          //         type: 'info',
          //         message: '申请成功'
          //       });
          //     }).catch(err => {
          //       loading.close()
          //     })
          //
          //   })
          // }
          // if (that.timeItr === 3) {
          //   // alert(that.timeItr)
          //   this.$alert('<strong>该合同离线密码历史累计时长已超15天<br>需审批通过方可设置成功，是否继续？</strong>', '提示', {
          //     dangerouslyUseHTMLString: true,
          //     distinguishCancelAndClose: true,
          //     showCancelButton: true,
          //     center: true,
          //     confirmButtonText: '确认',
          //     cancelButtonText: '取消'
          //   }).then(() => {
          //     const loadingOptions = this.loadingOptions
          //     const loading = this.$loading({...loadingOptions})
          //     let {apartmentUuid, phone, isDelete, days} = that.formPublish
          //     if (apartmentUuid === null) {
          //       MessageError('当前房源没有智能门锁无法发送')
          //       return
          //     }
          //     apartmentUuid = that.equipmentData.apartmentUuid
          //     offLinePassApprovalPUT({
          //       apartmentUuid,
          //       phone,
          //       isDelete,
          //       days,
          //       totalHistoryDuration: this.totalHistoryDuration,
          //     }).then(res => {
          //       console.log(res)
          //       loading.close()
          //       this.$message({
          //         type: 'info',
          //         message: '申请成功'
          //       });
          //       this.clickCancel()
          //     }).catch(err => {
          //       console.log(err)
          //       loading.close()
          //       // MessageError('申请失败请联系管理员')
          //     })
          //
          //   })
          // }
          // if (this.timeItr === 1) {
          //   // alert(that.timeItr)
          //   const loadingOptions = this.loadingOptions
          //   const loading = this.$loading({...loadingOptions})
          //   let {apartmentUuid, phone, isDelete, days} = that.formPublish
          //   if (apartmentUuid === null) {
          //     MessageError('当前房源没有智能门锁无法发送')
          //     return
          //   }
          //   apartmentUuid = that.equipmentData.apartmentUuid
          //   iotTagLockTemporary({apartmentUuid, phone, isDelete, days})
          //       .then(res => {
          //         loading.close()
          //         MessageSuccess('密码发送成功')
          //         that.clickCancel()
          //       })
          //       .catch(err => {
          //         MessageError('密码发送失败')
          //         loading.close()
          //       })
          // }
            // alert(that.timeItr)
            const loadingOptions = this.loadingOptions
            const loading = this.$loading({...loadingOptions})
            let {apartmentUuid, phone, isDelete, days} = that.formPublish
            if (apartmentUuid === null) {
              MessageError('当前房源没有智能门锁无法发送')
              return
            }
            apartmentUuid = that.equipmentData.apartmentUuid
            iotTagLockTemporary({apartmentUuid, phone, isDelete, days})
                .then(res => {
                  loading.close()
                  MessageSuccess('密码发送成功')
                  that.clickCancel()
                })
                .catch(err => {
                  MessageError('密码发送失败')
                  loading.close()
                })
        }
      })
    }
  },
  watch: {}
}
</script>

<style scoped></style>
